<template>
  <b-card title="DO Processing">
    <b-col cols="12">
      <b-row>
        <b-col><b-button @click="back" variant="primary"> Back </b-button></b-col>  
      </b-row>
      <br>
      <b-col cols="12" class="mb-2">
        <div class="filter-responsive">
          <div class="input-group">
            <div class="input-group-prepend">
              <b-dropdown
                style="margin: 0 !important;"
                id="dropdown-1"
                :text="selectedFilter"
                class="m-md-2"
              >
                <b-dropdown-item @click="dropDownMenuClick" field="sapDo"
                  >DO</b-dropdown-item
                >
                <b-dropdown-item @click="dropDownMenuClick" field="sapSo"
                  >SO</b-dropdown-item
                >
                <b-dropdown-item @click="dropDownMenuClick" field="project"
                  >Project</b-dropdown-item
                >
                <b-dropdown-item @click="dropDownMenuClick" field="route"
                  >Route</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <!-- <b-form-select
              v-model="type"
              v-if="selectedField === 'serial' || selectedField === 'barcode'"
              :options="typeOptions"
              size="md"
              class="w-5"
            /> -->
            <!-- <b-form-select
              v-model="typeDo"
              v-if="selectedField === 'sapDo'"
              :options="typeDoOptions"
              size="md"
              class="w-5"
            /> -->
            <b-form-input
              v-if="selectedField !== 'warehouse'"
              :disabled="typeDo === 'Not Set Up' || type !== 'Set Up'"
              v-model="toFilter"
              placeholder="Filter.."
            />
            <!-- <b-form-select
              v-model="warehouse"
              v-if="selectedField === 'warehouse'"
              :options="warehouseOptions"
              size="md"
              class="w-25"
            >
            </b-form-select> -->
            <b-button @click="doFilter" variant="primary">Filter</b-button>
            <b-button @click="clearFilter" variant="primary">Clear</b-button>
          </div>
        </div>
      </b-col>
      <b-card>
        <b-row style="margin-bottom: 15px">
          <b-col cols="3"><strong>Item Model</strong></b-col>
          <b-col>: {{ itemModel }}</b-col>
        </b-row>
        <b-row style="margin-bottom: 15px">
          <b-col cols="3"><strong>Item Name</strong></b-col>
          <b-col>: {{ itemName }}</b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col cols="12">
          <b-table
            :items="dealers"
            :fields="fields"
            :filter="search"
            show-empty
            responsive
          >
            <template #cell(overdueAmount)="row">
              {{ row.item.overdueAmount.$numberDecimal }}
            </template>
            <template #cell(createdAt)="row">
              {{ row.item.createdAt | formatDate }}
            </template>
            <template #cell(deliveryDate)="row">
              {{ row.item.deliveryDate | formatDate }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item>
                  <b-button
                    @click="viewProcessing(row.item)"
                    variant="info"
                    class="mr-1"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template #cell(status)="row">
              {{ row.item.status === "" ? "Active" : "Inactive" }}
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
            first-number
            last-number
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import lodash from "lodash";
import vSelect from "vue-select";
import { dateFormat, sleep } from "@/utils/utils.js";

export default {
  data() {
    return {
      dealers: [],
      form: {
        data: ["Dealer 1", "Dealer 2"],
      },
      fields: [
        { key: "sapSo", label: "SO Number" },
        { key: "sapDo", label: "DO Number" },
        { key: "createdAt", label: "DO Created Date" },
        { key: "deliveryDate", label: "Delivery Date" },
        { key: "deliveryAddress1", label: "Delivery Address" },
        { key: "quantity", label: "Quantity" },
        { key: "actions", label: "Action" },
      ],
      search: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      tableLoading: true,
      toFilter: "",
      selectedFilter: "DO",
      selectedField: "sapDo",
      type: "Set Up",
      typeOptions: ["Set Up", "Not Set Up", "Skip Scan"],
      typeDo: "Set Up",
      typeDoOptions: ["Set Up", "Not Set Up"],
    };
  },
  mounted() {
    // this.getDoProcessing();
    let dealerCode = this.$route.params.dealerCode;
    let itemModel = this.$route.params.itemModel;
    let sapDo = this.$route.params.sapDo
    this.queryDelivery();
    /*
    this.filterDoProcessing({
      itemModel: itemModel,
      entry: this.perPage,
      dealerCode: dealerCode,
      sapDo: sapDo,
    })
      .then((el) => {
        //console.info('mounted ',el )
        this.dealers = el.data.data;
        this.itemName = el.data.itemData.description;
        this.itemModel = el.data.itemData.itemModel;
        this.sapDo = el.data.itemData.sapDo
        this.processMetadata(el.data.metadata);
      })
      .catch((err) => {
        console.log("PAGE_ERROR_PROCESSING");
        console.log({ err });
      });
      */
  },
  methods: {
    ...mapActions({
      getDealers: "dealer/getDealerWithQuery",
      getDoProcessing: "dashboard/getDoProcessing",
      filterDoProcessing: "dashboard/filterDOProcessingDashboard",
    }),
    viewProcessing(rowItem) {
      let dealerCode = this.$route.params.dealerCode;
      let itemModel = this.$route.params.itemModel;

      this.$router.push({
        name: "dashboard-detail-processing",
        params: {itemModel,dealerCode,id:rowItem._id},
      });
    },
    back(){
      this.$router.push({name:`dashboard-dealer`, query:{dealerCode:this.$route.params.dealerCode}})
    },
    onPageChange(page) {
      this.currentPage = page;
      this.queryDelivery();
    },
    queryDelivery() {
      let obj = {};
      if (this.filterStatus) {
        obj[this.selectedField] = this.toFilter;
      }
      let dealerCode = this.$route.params.dealerCode;
      let itemModel = this.$route.params.itemModel;
      this.filterDoProcessing({
        ...obj,
        itemModel: itemModel,
        dealerCode: dealerCode,
        page: this.currentPage,
        entry: this.perPage,
      })
        .then((el) => {
          this.dealers = el.data.data;
          this.itemName = el.data.itemData.description;
          this.itemModel = el.data.itemData.itemModel;
          // console.log("ek", el);
          this.processMetadata(el.data.metadata);
        })
        .catch((err) => {
          // console.log("PAGE_ERROR_PROCESSING");
          console.log({ err });
        });
    },
    dropDownMenuClick(event) {
      this.selectedFilter = event.target.text;
      this.selectedField = event.target.getAttribute("field");

      //every change selected filter, will reset type to :
      this.typeDo = "Set Up";
      this.type = "Set Up";
    },
    clearFilter() {
      this.selectedField = "sapDo";
      this.selectedFilter = "DO";
      this.toFilter = undefined;
      this.type = "Set Up";
      this.typeDo = "Set Up";

      this.filterDoProcessing({
        itemModel: itemModel,
        dealerCode: dealerCode,
      })
        .then((el) => {
          this.processMetadata(el.data.datametadata);
        })
        .catch((err) => {
          // console.log("PAGE_ERROR_WARR");
          console.log({ err });
        });
    },
    // getFilter() {
    //   if (this.selectedFilter === null) {
    //     return {};
    //   }
    //   let filter = {};

    //   let tempValue = this.toFilter;
    //   let tempFilter = this.selectedField;
    //   if (this.selectedFilter === "Warehouse") {
    //     tempValue = this.warehouse;
    //   }

    //   if (this.type === "Not Set Up" || this.typeDo === "Not Set Up") {
    //     tempValue = "Not Scan";
    //   }

    //   if (this.selectedFilter === "Serial" && this.type === "Skip Scan") {
    //     tempFilter = "skip";
    //     tempValue = true;
    //   }

    //   if (this.selectedFilter === "Barcode" && this.type === "Skip Scan") {
    //     tempFilter = "skip";
    //     tempValue = true;
    //   }

    //   filter[tempFilter] = tempValue;

    //   return filter;
    // },
    doFilter() {
      let dealerCode = this.$route.params.dealerCode;
      let itemModel = this.$route.params.itemModel;
      this.filterStatus = true;
      let obj = {};
      obj[this.selectedField] = this.toFilter;
      let payload = {
        ...obj,
        itemModel: itemModel,
        dealerCode: dealerCode,
        // page: this.currentPage,
        entry: this.perPage,
      };
      this.filterDoProcessing(payload).then((el) => {
        this.dealers = el.data.data;
        this.processMetadata(el.data.metadata);
      });
      // let filter = this.getFilter();
      // this.getAllInventories({
      //   entry: this.perPage,
      //   ...filter,
      // }).then((data) => this.processMetadata(data.metadata));
    },
    doFiltering: lodash.debounce(function(value) {
      this.search = value;
      this.currentPage = 1;
      this.queryDealer();
    }, 500),
    processMetadata(metadata) {
      console.log("metadata", metadata);
      let { totalData } = metadata;
      this.totalRows = totalData;
    },
  },
  computed: {},
  created() {
    document.title = "Dealer | RSP";
    //this.getDealers({
    //  page: this.currentPage,
    //  entry: this.perPage,
    //}).then((data) => this.processMetadata(data.metadata));
  },
};
</script>
